<template>
    <div class="erp">
        <van-grid :column-num="3">
            <van-grid-item icon="discount" :text="$t('询价')" :badge="dataCnt.enquiryCnt>0?dataCnt.enquiryCnt:''" @click="toPc"/>
            <van-grid-item icon="brush-o" :text="$t('样品')" :badge="dataCnt.testCnt>0?dataCnt.testCnt:''" @click="toPc"/>
            <van-grid-item icon="gift-o" :text="$t('订单')" :badge="dataCnt.purchaseCnt>0?dataCnt.purchaseCnt:''" @click="toPc"/>
            <van-grid-item icon="friends-o" :text="companyMenu" :badge="dataCnt.supplierCnt>0?dataCnt.supplierCnt:''" @click="toPc"/>
            <van-grid-item icon="shop-o" :text="$t('产品定价')" @click="toGoodsPrice()"/>
            <van-grid-item icon="goods-collect-o" :text="$t('产品需求')" @click="toPc" />
        </van-grid>
        <div class="gray-bg">
            <div class="article-card" v-for="item in noticeList.data" :key="item.id" @click="showDetail(item)">
                <div class="article-header">
                    {{item.content}}
                </div>
                <div class="article-bottom">
                    <span>{{item.order.name}}</span>
                    <span class="article-date">{{item.create}}</span>
                </div>
            </div>

        </div>
        <van-overlay :show="loadFlag">
            <div class="wrapper" @click.stop>
                <van-loading type="spinner" size="34px" color="#FFFFFF" />
            </div>
        </van-overlay>
    </div>
</template>

<script>

    import {Toast} from "vant";

    export default {
        name: "ErpPanel",
        data() {
            return {
                loadFlag:true,//加载层
                noticeList: [],
                dataCnt: {},
                companyMenu:this.$t('供应商'),
            };
        },
        mounted() {
            this.companyMenu = this.$store.state.identity==1?this.$t('供应商'):this.$t('客户');
            this.getErpData();
        },
        methods: {
            getErpData(){
                let that = this;
                that.loadFlag = true;
                this.$http
                    .post("/v1/getErpData", {
                        type: 'cus',
                    })
                    .then((res) => {
                        that.loadFlag = false;
                        if (res&&res.code == 200) {
                            that.noticeList = res.data.noticeList;
                            that.dataCnt = res.data.dataCnt;
                        }
                    });
            },
            showDetail(obj){
                console.log(obj);
                Toast(this.$t("请到电脑端查看"));

            },
            toGoodsPrice(){
                let nowIdentity = this.$store.state.identity;
                if(nowIdentity==1){
                    this.$router.push('/GoodsPriceCus');
                }else{
                    this.$router.push('/GoodsPriceSup');
                }

            },
            toPc() {
                Toast(this.$t("请到电脑端查看"));
            },
        },
    };
</script>

<style scoped>
    .topc-show .van-grid-item__content{
        background: #b3b3b3 !important;
    }
    .erp {
        display: flex;
        flex-direction: column;
        max-height: 100vh;
        background: #f5f6fa;
    }
    .gray-bg{
        background-color: #fafafa;
    }
    .article-card{
        position: relative;
        box-sizing: border-box;
        padding: 8px 0px;
        color: #323233;
        font-size: 12px;
        background-color: #FFFFFF;
        margin: 5px 0px;
        min-height: 70px;
    }
    .article-card span{
        padding: 0px 5px;
    }
    .article-header{
        padding: 5px;
    }
    .article-body{
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        width: 100vw;
    }
    .article-bottom{
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        position: absolute;
        bottom: 5px;
        width: 100vw;
    }
    .article-date{
        margin-left: auto;
        padding-right: 20px !important;
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
</style>